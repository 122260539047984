import { Trans, useTranslation } from 'react-i18next'
import { inPublicAssets } from '../src/utilities/functions'
import LetsConnect from './layout/lets-connect/components/lets-connect-form-wrapper';
import styled from "styled-components";



function Hero() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('home.hero.' + str, options)
  return (
    <StyledDiv >
      <div className="md:h-full relative z-0 mt-14 h-auto mb-24 lg:mb-0 z-20" id="form">
        <img src={inPublicAssets('hero/Banner.png')} className="absolute block w-full z-0" alt="Hero Banner" />
        <div className='w-full  relative z-10 bottom-0 top-0'>
          <div className='lg:max-w-7xl max-w-mobileZone relative m-auto gridCustom lg:h-full lg:min-h-[845px]'>
              <div className='ml-0 max-w-2xl w-full text-left fade-in-left mt-[170px] lg:mt-0 lg:relative '>
                  <h1 className='lg:text-3xl lg:mb-[27px] mb-4 text-[29px] lg:mt-0 -mt-10'> 
                  <Trans i18nKey="home.hero.title" components={{ b: <b /> }} ></Trans></h1>
                  <p className='text-2xl lg:mb-[120px] mb-4'>{t('content')}</p>
              </div> 
              <div className=' w-full max-w-[440px] mx-auto lg:mt-0 mt-10 fade-in-right lg:right-0 lg:absolute lg:top-[90px] '>
                <LetsConnect/>
              </div>
            </div>
          </div>
        </div>
    </StyledDiv>
    
  );
}

export default Hero;

const StyledDiv = styled.div`
.gridCustom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
	
  @media only screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 10px;
    grid-template-areas: ". .";
    align-items: self-end;
    justify-content: initial;
  }
}

`;