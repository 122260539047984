import { inPublicAssets } from '../src/utilities/functions'
import styled from "styled-components";
import { Trans, useTranslation } from 'react-i18next'

function FirstSteps() {
    const { t: translate } = useTranslation()
    const t = (str, options) => translate('firstSteps.' + str, options)
    return (
        <StyledDiv >
            <div className="w-full max-w-7xl mx-auto" id="firstSteps">
                <div className="lg:flex pt-20 lg:pt-40 pb-[40px] lg:pb-[60px]">
                    <div className="w-full max-w-mobileZone lg:max-w-1/2 relative lg:absolute left-0 z-0 fade-in-left ">
                        <img src={inPublicAssets('elements/firstSteps.png')} alt="chica freelancer" />
                    </div>
                    <div className="w-full max-w-5/12 hidden lg:block">

                    </div>
                    <div className="w-full max-w-mobileZone lg:max-w-7/12 mx-auto mt-20 lg:m-auto z-10 face-in-place">
                        <h4 className="text-4xl mb-14">{t('title')}</h4>
                        <div>
                            <div className="flex lg:items-start justify-between lg:justify-start items-start mb-20 fade-in-right">
                                <span className="text-[60px] block min-w-[70.81px] leading-none">01</span> 
                                <div className="block lg:pl-16 pl-4 lg:max-w-sm xl:max-w-[390px]  max-w-[250px]" ><p>Completa tu aplicación en el<a target="_self" href="/#form" className='text-[#007FED]'> formulario de registro </a> y refiérelo a tus conocidos.</p></div>
                            </div>
                            <div className="flex lg:items-start justify-between lg:justify-start flex-wrap flex-row mb-20 fade-in-right">
                                <span className="text-[60px] block leading-none">02</span>
                                <ul className="block lg:pl-16 pl-4 lg:max-w-sm xl:max-w-[390px]  max-w-[250px]">
                                    <li>Revisamos tu información y si haces match con un proyecto activo.</li>
                                    <li>Vemos tus datos y alineamos tu experiencia con los proyectos activos y disponibles.</li>
                                </ul>
                            </div>
                            <div className="flex lg:items-start justify-between lg:justify-start items-start mb-20 fade-in-right"><span className="text-[60px] block leading-none">03</span>
                            <div className="lg:pl-16 pl-4 lg:max-w-sm xl:max-w-[390px]  max-w-[250px]">
                                <p>Te contactamos, para conocerte mejor.<br/>
                                 Estaremos en contacto contigo para programar una entrevista y saber más de tí.</p>
                                </div> 
                            </div>
                            <div className="flex lg:items-start justify-between lg:justify-start items-start mb-20 fade-in-right"><span className="text-[60px] inline-flex leading-none">04</span>
                            <div className="lg:pl-16 pl-4 lg:max-w-sm xl:max-w-[390px]  max-w-[250px]"> <p>Iniciamos proyecto, al alinear y negociar expectativas.
                                <b className="block">Muéstranos tus mejores skills y brilla en el proyecto asignado.</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
     
    );
  }
  
  export default FirstSteps;
  
  const StyledDiv = styled.div`
  p{font-size: 18px}
  ul{
    li{
        list-style-type: disc;
        margin-bottom: 20px;
    }
  }
`;