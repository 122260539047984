import { inPublicAssets } from '../../../utilities/functions'


function TopMenuLinks(props) {
  const links = props.links || []
  return (
    <div className='w-full flex flex-row xl:pr-14'>
    <ul className="hidden md:flex flex-row items-center justify-center flex-grow">
      {links.map((link, index) => {
        let { text, ...attr } = link
        return (
          <li key={index} className="px-4">
            <a {...attr}>{text}</a>
            
          </li>
        )
      })}
    </ul>
    <a target="_self"  href="#form" className='bg-btnBg font-semibold py-2 px-4 rounded-3xl mx-auto text-base lg:text-lg max-w[193px] max-h-[50px] flex lg:max-w-[197px] items-center box-border'>Let's Connect <span className='pl-2'> <img src={inPublicAssets('buttons/arrowClick.svg')} alt="arrow"/></span></a>
    </div>
    
  )
}

export default TopMenuLinks