import { useTranslation } from 'react-i18next'
import { inPublicAssets } from '../../utilities/functions'

function Footer() {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('footer.' + str, options)

  const links = t('links', { returnObjects: true }) || []

  return (
    <footer
      className={`footer bg-bgCategories py-12`}
    >
      <div className='w-full max-w-7xl m-auto text-center'>
        <img src={inPublicAssets('brand/logo.svg')}  className="text-center block mx-auto my-6" alt="Logo LDM"/>
        <p>© Copyright 2022 | By DigitLancer</p>
      </div>
     
    </footer>
  )
}

export default Footer
