import { inPublicAssets } from '../src/utilities/functions'
import { Trans, useTranslation } from 'react-i18next'

function Talent() {
    const { t: translate } = useTranslation()
    const t = (str, options) => translate('talent.' + str, options)

  return (
    <div className="w-full relative z-10" id="aboutUs">
        <div className='w-full max-w-mobileZone lg:max-w-7xl m-auto '>
            <div className='absolute left-0 w-full lg:max-w-[450px] xl:max-w-[520px] max-w-[196px] top-0 lg:min-h-[450px] lg:mt-0  fade-in-right z-0'>
                <img src={inPublicAssets('elements/elementTalent.svg')} alt="Element" className='block object-fill lg:w-full 2xl:mt-0 md:mt-16'/>
            </div>
            <div className='lg:pt-[285px] pt-44 relative z-10'>
                <div className='flex flex-wrap justify-between max-w-[1100px] ml-auto mr-0'>
                    <h2 className='font-medium max-w-md text-3xl max-w-md w-full fade-in-place mb-6 lg:mb-0' > {t('title')}</h2>
                    <div className='w-full max-w-[520px] text-lg ml-0 fade-in-place'>
                        <p className='mb-6'><Trans i18nKey="talent.content" components={{ a: <br /> }} ></Trans>
                        </p>
                    </div>
                </div>

                <div className='w-full mt-[110px] flex justify-between max-w-[880px] mx-auto flex-wrap fade-in-place-children'>
                    <div className='text-base px-1 lg:text-lg  text-center border-2 border-dashed box-border rounded-lg border-lightGrey w-full max-w-[153px] lg:max-w-[194px] flex flex-col h-[126.55px] lg:h-[163px] relative justify-center text-lg'>
                        <div className="scale-[0.79] -mt-[125px] lg:-mt-[160px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-lightGrey w-[78px] h-[78px]">
                            <img src={inPublicAssets('talent/talentBorderless.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                        </div>
                        <p className='mt-2'>Borderless</p>
                        <p>Make Things Happen</p>
                    </div>
                    <div className='text-base px-1 lg:text-lg  text-center border-2 border-dashed box-border rounded-lg border-lightGrey w-full max-w-[153px] lg:max-w-[194px] flex flex-col h-[126.55px] lg:h-[163px] relative justify-center text-lg'>
                        <div className="scale-[0.79] -mt-[125px] lg:-mt-[160px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-lightGrey w-[78px] h-[78px]">
                            <img src={inPublicAssets('talent/talentTalent.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                        </div>
                        <p className='mt-8'>Talent</p>
                        <p>Forward Thinking</p>
                        <p>OpenWork</p>
                    </div>
                    <div className='text-base px-1 lg:text-lg  text-center border-2 border-dashed box-border rounded-lg border-lightGrey w-full max-w-[153px] lg:mt-0 mt-20 lg:max-w-[194px] flex flex-col h-[126.55px] lg:h-[163px] relative justify-center text-lg'>
                        <div className="scale-[0.79] -mt-[125px] lg:-mt-[160px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-lightGrey w-[78px] h-[78px]">
                            <img src={inPublicAssets('talent/talentInnovation.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                        </div>
                        <p className='mt-8'>Innovation</p>
                        <p>Freelancer</p>
                        <p>Global Experience</p>
                    </div>
                    <div className='text-base px-1 lg:text-lg  text-center border-2 border-dashed box-border rounded-lg border-lightGrey w-full max-w-[153px] lg:mt-0 mt-20 lg:max-w-[194px] flex flex-col h-[126.55px] lg:h-[163px] relative justify-center text-lg'>
                        <div className="scale-[0.79] -mt-[125px] lg:-mt-[160px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-lightGrey w-[78px] h-[78px]">
                            <img src={inPublicAssets('talent/talentWorldClass.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                        </div>
                        <p className='mt-8'>World Class</p>
                        <p>Solutions</p>
                        <p>Digital Platforms</p>
                    </div>
                </div>
                
                <div className='block w-full text-center lg:mb-[90px] mb-[160px] fade-in-place'>
                    <h3 className='mt-10 mb-6 mx-auto text-2xl '>Si te identificas con estas ideas</h3>
                    <a target="_self" href="/#form" className='bg-btnBg font-semibold py-2 px-4 rounded-3xl mx-auto text-lg flex max-w-[235px]  items-center justify-between'>Regístrate aquí <span className='pl-4'> <img src={inPublicAssets('buttons/arrowClick.svg')} alt="arrow"/></span></a>
                </div>


            </div>
        </div>
    </div>
  );
}

export default Talent;
