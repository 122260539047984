import { inPublicAssets } from '../src/utilities/functions'
import { Trans, useTranslation } from 'react-i18next'


function Categories() {
    const { t: translate } = useTranslation()
    const t = (str, options) => translate('categories.' + str, options)
  return (
    <div className='w-full  mt-56 relative' id="categories">
        <div className='absolute left-0 w-full max-w-[200px] lg:max-w-[600px] top-0 lg:min-h-[600px] mt-[-230px] fade-in-left'>
                <img src={inPublicAssets('elements/elementCategories.svg')} alt="Element" className='block object-fill lg:w-full 2xl:mt-0 md:mt-16'/>
        </div>
        <h3 className='text-3xl mb-16 text-center fade-in-place'>{t('title')}</h3>
        <div className="lg:flex lg:flex-wrap lg:flex-row h-full lg:bg-transparent bg-bgCategories">
            <div className="lg:max-w-1/2 max-w-mobileZone mx-auto w-full text-center flex h-auto items-center justify-start lg:justify-center fade-in-left lg:bg-transparent bg-bgCategories pt-10 lg:pt-0">
                <h4 className="text-[41px] lg:text-5xs self-center">TECH</h4>
            </div>
            <div className="lg:max-w-1/2 w-full lg:pl-14 py-10 bg-bgCategories fade-in-right">
                <div className='pl-6 lg:pl-0 lg:max-w-full m-auto box-border max-w-mobileZone'>
                    <ul className="flex flex-row flex-wrap w-full list-disc max-w-mobileZone lg:max-w-full m-auto">
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Front - End Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Back - End Developer 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Business Intelligence
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        UX  UI Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Python Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Laravel Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        React Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        .NET Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Big Query Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        VTEX Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Big Commerce Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Shopify Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] xl:max-w-1/2 w-full mb-6">
                        Wordpress | Woocommerce Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Full stack developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Tag Manager Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Analytics Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Tagging Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        BI Insights Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Javascript Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Node Developer
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Salesforce Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Zoho Specialist 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Drupal Developer 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Linux Server Administrator 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        AWS Specialist 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Cloud Specialist 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Data Studio Specialist 
                        </li>
                    </ul>
                </div>
                
            </div>

            <div className='flex flex-wrap w-full'>
                <div className="lg:max-w-1/2 w-full  py-14 bg-white lg:bg-bgCategories fade-in-left order-2 lg:order-1">
                    <div className='max-w-full lg:max-w- w-full pl-14 py-10 bg-white lg:bg-bgCategories  fade-in-left order-2 lg:order-1'>
                        <ul className="flex flex-row flex-wrap w-full list-disc max-w-mobileZone lg:max-w-full m-auto  ">
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Visual Design
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            User Experience (UX)
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            User Research (UX)
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            User Research (UX)
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            UX Copywriting (UX)
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Copywriting
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Video edition  
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            UX UI Designer
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Graphic designer                           
                             </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Illustrator                            
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            Motion graphics                            
                            </li>
                            <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                            SEO - Search Engine Optimization                          
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lg:max-w-1/2 w-full text-center flex h-auto items-center justify-center fade-in-right order-1 lg:order-2 bg-white pt-10 lg:pt-0 ">
                    <div className='max-w-mobileZone w-full mx-auto'>
                        <h4 className="text-[41px] lg:text-5xs self-center text-left">Diseño y Contenido</h4>
                    </div>
                    
                </div>
            </div>
            


            <div className="lg:max-w-1/2 max-w-mobileZone mx-auto w-full text-center flex h-auto items-center justify:start lg:justify-center fade-in-left pt-10 lg:pt-0">
                <h4 className="text-[41px] lg:text-5xs self-center text-left">Campañas <br/> Digitales</h4>
            </div>
            <div className="lg:max-w-1/2 w-full lg:pl-14 py-14 bg-bgCategories fade-in-right">
                <div className='pl-6 lg:pl-0 lg:max-w-full m-auto box-border max-w-mobileZone'>
                    <ul className="flex flex-row flex-wrap w-full list-disc max-w-mobileZone lg:max-w-full m-auto">
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Shopping
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Tag Manager Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Analytics Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Analytics
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Google Ads
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        SEM - Search Engine Marketing
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Social Media Advertising
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        DV360
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Digital Marketing Advertising
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Facebook Shopping
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Mediamath expert
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Digital Media planning
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Live Shopping 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        TikTok Ads
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Pinterest Ads
                        </li>

                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Search Ads 360
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Amazon Advertising 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Amazon DSP
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Adservers (Sizmek, AdManager, etc)
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Programmatic Dealer
                        </li>

                    </ul>
                </div>
                
            </div>

           <div className='flex flex-wrap w-full lg:bg-transparent bg-white'>
                <div className="max-w-full lg:max-w-1/2 w-full pl-14 py-10 bg-white lg:bg-bgCategories  fade-in-left order-2 lg:order-1">
                    <ul className="flex flex-row flex-wrap w-full list-disc max-w-mobileZone lg:max-w-full m-auto">
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Account Manager 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        CRM
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Digital Account Management 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Project Management
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Digital Strategy 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        E-Commerce Specialist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Growth Marketing 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Strategic planner
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        QA
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Paid Media Strategist
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Account Based Marketing
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Demand Generation
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Project Management - Design
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Project Management - Web
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Product Management - E-commerce
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Omni Experience Strategy 
                        </li>
                        <li className="text-lg lg:max-w-[49%] lg:pr-[5px] xl:pr-0 w-full mb-6">
                        Data Analysis + Mangement 
                        </li>
                    </ul>
                </div>

            <div className="max-w-mobileZone mx-auto lg:max-w-1/2 w-full text-center flex h-auto items-center justify-start lg:justify-center fade-in-right order-1 lg:order-2 pt-10 lg:pt-0">
                <h4 className="text-[41px] lg:text-5xs self-center text-left">Cuentas</h4>
            </div>
           </div>
            
            

        </div>
    </div>
  );
}

export default Categories;
