import React, { useLayoutEffect, memo } from 'react'
import Hero from '../../Hero';
import Talent from '../../Talent';
import Beneficios from '../../Beneficios';
import Categories from '../../Categories';
import FirstSteps from '../../firstSteps';
import Partners from '../../partners';

const Home = memo(() => {
  useLayoutEffect(() => {}, [])

  return (
    <>
      <Hero />
      <Talent/>
      <Beneficios/>
      <Categories/>
      <FirstSteps/>
      <Partners/>
    </>
  )
})

export default Home
