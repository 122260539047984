import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from '../../../components/form-elements/input'
import FieldSet from '../../../components/form-elements/fieldset'
import styled from "styled-components"
import { inPublicAssets } from '../../../utilities/functions'

function LetsConnectForm({
  onSubmit = () => console.log('No submit function provided'),
  isFormSubmitting = true,
}) {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('lets-connect.form.' + str, options)

  const formFields = t('fields', { returnObjects: true })

  /**
   * Form initialization
   */
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      /**
       * Cambios por riccardo llevar a translation json luego
       */
      availablePosition: []
    }
  })

  return (
    <StyledDiv >
      <div className="flex flex-col md:flex-row items-start justify-start w-full bg-white py-10 px-10 rounded-2xl shadow border border-[#f2f2f2]">
        <div className="w-full pb-2">
          <h3 className='text-[2rem] text-center mb-6'>¡Inscríbete aquí!</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block">
              {formFields.map((field, index) => {
                const {
                  name,
                  id,
                  label,
                  placeholder,
                  type,
                  options,
                  switchOptions,
                  classes,
                  required, //retirar
                  helperText,
                  validation,
                } = field
                if (type === 'email') {
                  validation.pattern.value =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }
                if (name === 'countryCode'){
                  validation.pattern.value=
                  /^(\+?\d{1,3}|\d{1,4})$/
                }
                if (type === 'tel') {
                  validation.pattern.value =
                  /^[0-9]{3}[-\s\.]?[0-9]{4,7}$/im
                }
                return (
                  <div
                    key={index}
                    className={`flex flex-row col-span-1 stagger relative ${
                      ['textarea'].includes(type) ? 'md:col-span-2' : ''
                    }`}
                  >
                    <FieldSet
                      errors={errors}
                      validation={validation}
                      name={name}
                      label={label}
                      helperText={helperText}
                      watch={watch}
                    >
                      <Input
                        type={type}
                        name={name}
                        id={id}
                        register={register}
                        validation={validation}
                        placeholder={placeholder}
                        options={options}
                        switchOptions={switchOptions}
                        watch={watch}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                      />
                    </FieldSet>
                  </div>
                )
              })}
              {/*Campo de prueba*/}
              
            </div>
            <div className="flex justify-start pt-4 ">
              <button
                type="submit"
                value="submit"
                className="stagger w-[15em] flex justify-center items-center bg-btnBg border-main transition-colors duration-300 hover:bg-transparent hover:text-main py-2 px-3 rounded-full text-black"
              >
                {isFormSubmitting ? t('submit.submitting') : t('submit.label')} <span className='pl-4'> <img src={inPublicAssets('buttons/arrowClick.svg')} alt="arrow"/></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </StyledDiv>
    
  )
}

export default LetsConnectForm


const StyledDiv = styled.div`
  .shadow {
    box-shadow: 0px 5.8px 3.7px rgba(0, 0, 0, 0.02), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03), 0px 87px 55px rgba(0, 0, 0, 0.05);
  }
  @media only screen and (max-width: 1064px) {
    .shadow {
      box-shadow: 0px 5.8px 3.7px rgba(0, 0, 0, 0.02), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03), 0px 87px 55px rgba(0, 0, 0, 0.05), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03)
      ,0px 0px 3.7px rgb(0 0 0 / 2%), 0px -6px 12.3px rgb(0 0 0 / 3%), 0px -10px 55px rgb(0 0 0 / 5%);
    }
  }

  form {
    .flex.flex-row:nth-child(3) {
      max-width: 25%;
      display: inline-flex;
  
      .fixcountryCode {
        opacity: 0;
        background: #fff;
        width: 235px;
        top: 50px;
        z-index: 99999;
        font-size: 16px;
        border-radius: 5px;
        box-shadow: 0px 0px 18px 3px #bebebe;
        box-sizing: border-box;
        transition: all 0.5s ease;
      }
  
      &:hover .fixcountryCode {
        opacity: 1;
      }
    }
  
    > div > .flex.flex-row:nth-child(4) {
      max-width: 73%;
      display: inline-flex;
      width: 100%;
      float: right;
    }
  }

`;
