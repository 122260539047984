import { ErrorMessage } from '@hookform/error-message'

function FieldSet({
  errors = {}, //errors ofject from react-hook-form
  validation = {}, //validation object from react-hook-form
  watch = {}, //watch object from react-hook-form
  name = "", //name of the field
  label = "", //label of the field
  helperText = "", //helper text of the field
  ...props
}) {
  return (
    <fieldset
      className={`form-field relative flex-grow ${
        errors?.[name] ? 'border-main-red' : ''
      }`}
      
    >
      {props.children}
      <label
        htmlFor={name}
        className={`leading-none whitespace-no-wrap ${
          errors?.[name] ? 'text-main-red' : 'text-main'
        }`}
      >
        <div className="jumping-label">{label}</div>
      </label>
      <legend>{label}</legend>
      <div
        className={`absolute top-[calc(100%+0.33em)] left-0 w-full text-xs flex flex-row justify-start items-start px-2 fixcountryCode ${
          errors?.[name] ? 'text-main-red' : 'text-main'
        }`}
      >
        {errors?.[name] ? (
          <div className="text-main-red flex-grow">
            <ErrorMessage errors={errors} name={name} />
          </div>
        ) : (
          <div className="text-main flex-grow">{helperText}</div>
        )}
        {validation?.maxLength && (
          <div className="w-12 text-right">
            {watch(name)?.length}/{validation.maxLength.value}
          </div>
        )}
      </div>
    </fieldset>
  )
}


export default FieldSet