import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const desktopToggleActions  = "restart none none reverse"
const mobileToggleActions  = "play none none none"

function toggleActions(isDesktop) {
  if (isDesktop) {
    return desktopToggleActions
  } else {
    return mobileToggleActions
  }
}

export const inPublicAssets = (path) =>
  process.env.PUBLIC_URL + '/assets/images/' + path

export function fullViewportHeight () { 
  return window.innerHeight > window.screen.availHeight ? window.innerHeight : window.screen.availHeight
}

export function hideWhenOffViewPort(q, elements) {
  q(elements).forEach(function (elem) {
    ScrollTrigger.create({
      start: 0,
      end: 'max',
      onUpdate: elementInViewport,
    })

    function elementInViewport() {
      if (!ScrollTrigger.isInViewport(elem)) {
        //console.log("not in viewport")
        elem.classList.add('invisible')
      } else {
        return false
        //elem.classList.remove('invisible')
        //console.log("in viewport")
      }
    }
  })
}

export function fadeInPlace(q, elements, isDesktop) {
  q(elements).forEach(function (elem) {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
      },
      {
        duration: 1,
        opacity: 1,
        scrollTrigger: {
          trigger: elem,
          start: 'top 70%',
          toggleActions: toggleActions(isDesktop),
          once:true,
          //markers: true,
        },
      },
      
    )
  })
}

export function fadeInPlaceChildren(q, elements, isDesktop=true) {
  q(elements).forEach(function (elem) {
    gsap.fromTo(
      elem.children,
      {
        opacity: 0,
      },
      {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        scrollTrigger: {
          trigger: elem,
          start: 'top 70%',
          toggleActions: toggleActions(isDesktop),
          once:true,
          //markers: true,
        },
      }
    )
  })
}

export function fadeInLeft(q, elements, isDesktop=true) {
  q(elements).forEach(function (elem) {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
        x: -20
      },
      {
        duration: 1,
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'top 70%',
          toggleActions: toggleActions(isDesktop),
          once:true,
          //markers: true,
        },
      }
    )
  })
}

export function fadeInRight(q, elements, isDesktop=true) {
  q(elements).forEach(function (elem) {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
        x: 20
      },
      {
        duration: 1,
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'top 70%',
          toggleActions: toggleActions(isDesktop),
          once:true,
          //markers: true,
        },
      }
    )
  })
}

export function fadeInBottom(q, elements, isDesktop=true) {
  q(elements).forEach(function (elem) {
    gsap.fromTo(
      elem,
      {
        opacity: 0,
        y: 20
      },
      {
        duration: 1,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'top 70%',
          toggleActions: toggleActions(isDesktop),
          once:true,
          //markers: true,
        },
      }
    )
  })
}

export function setScrolledClass(q, trigger) {
  let animationObj = gsap.timeline({
    scrollTrigger: {
      trigger: q(trigger),
      id: 'scrolled class',
      start: 'top -15',
      end: 'bottom+=100 bottom',
      toggleClass: { targets: 'body', className: `scrolled` },
    },
  })
  return animationObj
}
