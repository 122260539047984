import LetsConnectThankYou from './lets-connect-thank-you'
import LetsConnectForm from './lets-connect-form'
import { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const formActionUrl = 'https://hooks.zapier.com/hooks/catch/638494/bgfzn0m/'

function LetsConnectFormWrapper() {
  const { i18n } = useTranslation()
  const [isFormSent, setIsFormSent] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const dataLayer = []

  function formSubmitStart() {
    setIsFormSubmitting(true)
  }

  function formSubmitSuccess() {
    setIsFormSubmitting(false)
    setIsFormSent(true)
    window.dataLayer.push({
      event: 'lead'
    });
  }

  function formSubmitFailed() {
    setIsFormSubmitting(false)
    setIsFormSent(false)
    
  }

  const onSubmit = (data) => {
    console.log(data)
    formSubmitStart()
    var freelancerForm = new FormData()
    freelancerForm.append('language', i18n.language)
    freelancerForm.append('name', data.name)
    freelancerForm.append('email', data.email)
    freelancerForm.append('phone', data.phone)
    freelancerForm.append('country', data.country)
    freelancerForm.append('levelexp', data.levelexp)
    freelancerForm.append('specialty', data.specialty)
    freelancerForm.append('cv', data.cv[0])
    freelancerForm.append('profile', data.profile)

    let positionsArray = []
    data.availablePosition.map(({value}, index) => {
      positionsArray.push(value)
    })
    freelancerForm.append('availablePosition', positionsArray.join(', '))
    /**
     * commented axios post until form is finished
     */
    axios
      .post(formActionUrl, freelancerForm)
      .then((response) => {
        formSubmitSuccess()
      })
      .catch((error) => {
        formSubmitFailed()
      })
  }

  return isFormSent ? (
    <LetsConnectThankYou />
  ) : (
    <LetsConnectForm onSubmit={onSubmit} isFormSubmitting={isFormSubmitting} />
  )
}

export default LetsConnectFormWrapper
