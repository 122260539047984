import { inPublicAssets } from '../../utilities/functions'
import prettyBytes from 'pretty-bytes'

function InputTypeFile({
  name = 'text', //field name,
  id = '', //field id,
  register = () => {}, //register function from react-hook-form,
  validation = {}, //validation object from react-hook-form,
  placeholder = '', //placeholder text,
  watch = () => {},
 }) {

  function isFileUploaded(file) {
    return file && file[0] !== undefined ? true : false
  }
  
  // function isFileUploaded(file) {
  // {/*debugger*/}
  //   if(file && file[0] && file[0].size <= 1e+7){
  //       console.log('archivo con peso valido')
  //       return file && file[0] && file[0].size <= 1e+7 !== undefined ? true : false  
  //   }
  //   else {
  //     return false
  //   }
  // }

  function getFileDetails(file) {

    if (isFileUploaded(file)) {
      return {
        name: file[0].name,
        size: prettyBytes(file[0].size),
        type: file.type,
      }
    }

    
    return false
  }

  function openFileDialog(id) {
    let fileInput = document.getElementById(id)
    fileInput.click()
  }


  return (
    <>
      <button
        className="h-full block flex-row items-center justify-start w-full text-ellipsis min-h-[110px] btnUpload "
        onClick={() => openFileDialog(id)}
        tabIndex={0}
        type="button"
      >
        <div className="w-14 mx-auto">
          <img
            src={inPublicAssets('/buttons/upload-icon.svg')}
            className={`w-full h-full object-contain ${isFileUploaded(watch(name)) ? 'hidden' : ''
              }`}
            alt="upload"
          />
          <img
            src={inPublicAssets(
              '/buttons/paperclip-check.svg'
            )}
            className={`w-3/4 h-3/4 object-contain ${!isFileUploaded(watch(name)) ? 'hidden' : ''
              }`}
            alt="upload"
          />
        </div>
        <div className="truncate text-xs text-left -bottom-[20px] absolute">
          {getFileDetails(watch(name))?.name || placeholder}
        </div>
      </button>
      <input
        type="file"
        id={id}
        name={name}
        accept=".pdf,image/*,.doc"
        className="hidden"
        {...register(name, {
          validate: {
            lessThan10MB: (file) => file[0]?.size < 10000000 || "El archivo debe pesar máximo 10MB.",
          }
        })}
        defaultValue={null}
      />
    </>
  )
}

export default InputTypeFile