import { Link as RouterLink } from 'react-router-dom'
import useAppContext from '../utilities/app-context'
import TextLinkArrow from './text-link-arrow'
import WithApplyNowLink from './with-apply-now-link'

function ActionWrapper(props) {
  const className = props.className || ''
  const arrow = props.arrow || false

  const linkClasses = props.linkClasses || ''

  const { openApplyForm,closeApplyForm, closeUniversitiesForm } = useAppContext()

  if (props.href) {
    return (
      <TextLinkArrow>
        <a
          href={props.href}
          className={className + linkClasses}
          target={props.target}
        >
          {props.children}
        </a>
      </TextLinkArrow>
    )
  }
  if (props.action) {
    const actions = {
      'open-apply-form': openApplyForm,
      'close-lets-connect-drawer': closeApplyForm,
      'close-lets-connect-university-drawer': closeUniversitiesForm
    }
    const action = Boolean(actions?.[props.action])
      ? actions[props.action]
      : () => {
          console.log(
            'function missing for action ' +
              props.action +
              ' check action name on translation file'
          )
        }
    return (
      <button className={className + linkClasses} onClick={action}>
        {arrow ? (
          <TextLinkArrow>{props.children}</TextLinkArrow>
        ) : (
          props.children
        )}
      </button>
    )
  }
  if (props.to) {
    return (
      <RouterLink className={className + linkClasses} to={props.to}>
        {props.children}
      </RouterLink>
    )
  }
  return <>{props.children}</>
}

export default ActionWrapper
