import { useTranslation } from 'react-i18next'
import ActionWrapper from '../../../components/action-wrapper'
import styled from "styled-components"
import { inPublicAssets } from '../../../utilities/functions'

function LetsConnectThankYou() {
  const { t: translate, i18n } = useTranslation()
  const t = (str, options) => {
    let baseString = 'lets-connect.form.thank-you.'
    return translate(baseString + str, options)
  }


  return (
    <StyledDiv >
      <div className="bg-white py-20 px-4 lg:mt-0 rounded-2xl shadow text-center">
        <img className='block mx-auto mt-4 mb-10' src={inPublicAssets('form/checkIcon.svg')} alt="Check Icon"/>
        <div className="text-[36px] mb-8 font-bold">{t('title')}</div>
          <p className="p1 mb-8 whitespace-pre-line">{t('message')}</p>
          {t('cta', { returnObjects: true }).map((cta, index) => {
            let { text, linkText, ...props } = cta
            return (
                
                <ActionWrapper {...props} key={index} arrow className="mx-auto w-[13em] transition-opacity ">{linkText}</ActionWrapper>
            )
          })}
      </div>  
    </StyledDiv>
    
  )
}

export default LetsConnectThankYou

const StyledDiv = styled.div`
  .shadow {
    box-shadow: 0px 5.8px 3.7px rgba(0, 0, 0, 0.02), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03), 0px 87px 55px rgba(0, 0, 0, 0.05);
  }
  @media only screen and (max-width: 1064px) {
    .shadow {
      box-shadow: 0px 5.8px 3.7px rgba(0, 0, 0, 0.02), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03), 0px 87px 55px rgba(0, 0, 0, 0.05), 0px 19.4px 12.3px rgba(0, 0, 0, 0.03)
      ,0px 0px 3.7px rgb(0 0 0 / 2%), 0px -6px 12.3px rgb(0 0 0 / 3%), 0px -10px 55px rgb(0 0 0 / 5%);
    }
  }

`;