import useAppContext from '../../../utilities/app-context'
import IconButtonBase from '../../../components/icon-button-base'

function MenuDrawerToggler() {
  const { openMenu } = useAppContext()

  return (
    <div
      id="drawer-toggler"
      className="absolute z-20 top-[50%] translate-y-[-50%] right-[var(--container-edge)]"
    >
      <button onClick={openMenu} className="split-on-hover  w-12 h-12">
        <IconButtonBase wrapperClasses=" w-12 h-12 border-2 border-white bg-light-grey ">
          <div className="split absolute top-0 h-[2px] bg-main w-full left-0" />
          <div className="split absolute bottom-0 h-[2px] bg-main w-full left-0" />
        </IconButtonBase>
      </button>
    </div>
  )
}

export default MenuDrawerToggler
