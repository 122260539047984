import { inPublicAssets } from '../src/utilities/functions'
import { Trans, useTranslation } from 'react-i18next'

function Beneficios() {
    const { t: translate } = useTranslation()
    const t = (str, options) => translate('benefits.' + str, options)
  return (
    <div className="bg-bgGreyLight relative" id="benefits">
        <img src={inPublicAssets('backgrounds/gradient.png')} alt="gradiente background " className='w-full absolute z-0' />
        <div className='absolute right-0 lg:left-0 w-full lg:mt-[-160px] mt-[-200px] z-0 fade-in-right max-w-[240px] mr-[-130px] lg:mr-0  lg:max-w-full '>
            <img src={inPublicAssets('elements/eclipse.png')} alt="eclipse" className=' ml-auto mr-0'/>
        </div>
        <div className='block w-full max-w-mobileZone lg:max-w-7xl m-auto pt-44 z-20 relative fade-in-place'>
            <h3 className='lg:text-5xl text-3xl mx-auto text-center mb-[72px] '> {t('title')}</h3>

            <div className='w-full mt-[110px] flex flex-wrap lg:justify-between mx-auto justify-center fade-in-place-children'>
                <div className=' px-7 text-center border-2 border-dashed box-border mb-14 rounded-lg border-main-green w-full max-w-[280px] flex flex-col h-[215px] relative text-lg pt-5 '>
                    <div className="-mt-[60px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-main-green w-[78px] h-[78px]">
                        <img src={inPublicAssets('benefits/reloj.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                    </div>
                    
                    <h4 className='mt-8 text-xl font-semibold mb-3 leading-none'>Flexibilidad en horas</h4>
                    <p className='text-base'>Proyectos adaptados a la disponibilidad de tus horarios</p>
                </div>
                <div className=' px-7 text-center border-2 border-dashed box-border mb-14 rounded-lg border-main-yellow w-full max-w-[280px] flex flex-col h-[215px] relative text-lg pt-5 '>
                    <div className="-mt-[60px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-main-yellow w-[78px] h-[78px]">
                        <img src={inPublicAssets('benefits/homeOffice.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                    </div>
                    
                    <h4 className='mt-8 text-xl font-semibold mb-3 leading-none'>Trabaja donde tú quieras</h4>
                    <p className='text-base'>Donde estés, puedes estar activo con nuestros proyectos</p>
                </div>
                <div className=' px-7 text-center border-2 border-dashed box-border mb-14 rounded-lg border-main-red w-full max-w-[280px] flex flex-col h-[215px] relative text-lg pt-5 '>
                    <div className="-mt-[60px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-main-red w-[78px] h-[78px]">
                        <img src={inPublicAssets('benefits/borderless.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                    </div>
                    
                    <h4 className='mt-8 text-xl font-semibold mb-3 leading-none'>Proyectos más allá de tu región</h4>
                    <p className='text-base'>Proyectos de grandes marcas a nivel regional</p>
                </div>
                <div className=' px-7 text-center border-2 border-dashed box-border mb-14 rounded-lg border-main-blue w-full max-w-[280px] flex flex-col h-[215px] relative text-lg pt-5 '>
                    <div className="-mt-[60px] absolute -translate-x-2/4 left-1/2 bg-white border-2 grid items-center border-dashed rounded-full border-main-blue w-[78px] h-[78px]">
                        <img src={inPublicAssets('benefits/certificado.png')} className="block mx-auto max-w-[40px]" alt="icon Talent Borderless" />
                    </div>
                    
                    <h4 className='mt-8 text-xl font-semibold mb-3 leading-none'>Actualízate con certificados digitales</h4>
                    <p className='text-base'>Mejora continua con programa exclusivo de certificaciones, con partner de la industria</p>
                </div>
            </div>
        </div>
        
    </div>
  );
}

export default Beneficios;
