import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { inPublicAssets } from '../../utilities/functions'
import MenuDrawerToggler from './components/drawer-menu-toggler'
import TopMenuLinks from './components/top-menu-links'

const NavigationBar = forwardRef((props, ref) => {
  const { t: translate } = useTranslation()
  const t = (str, options) => translate('navigation-bar.' + str, options)

  const topMenuItems = t('top-menu', { returnObjects: true })

  return (
    <div id="navbar-wrapper" className="fixed top-0 left-0 w-screen z-50 bg-white">
      <div
        id="navbar"
        ref={ref}
        className="inset-0 w-full flex flex-row items-center justify-between h-[var(--scrolled-navbar-height)] md:h-navbar"
      >
        <div className="container left-1/2 -translate-x-1/2 relative">
          <div className="flex flex-row items-center justify-between py-4 pr-12">
            <div className="h-10">
              <img
                src={inPublicAssets('brand/logo.svg')}
                className="h-full w-auto"
                alt={t('brand-link.alt')}
              />
            </div>
            <TopMenuLinks links={topMenuItems} />
          </div>
        </div>
      </div>
      <MenuDrawerToggler />
    </div>
  )
})

export default NavigationBar
