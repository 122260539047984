import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './i18n.config';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './utilities/app-context';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => { };
} else {
  reportWebVitals(console.log);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

