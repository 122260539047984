import { inPublicAssets } from '../src/utilities/functions'

function Partners() {
  return (
    <div className="w-full max-w-7xl mx-auto mb-24" id="partners">
        <h4 className='text-4xl text-center mb-16 fade-in-place'>Partners</h4>
        <div className='w-full flex flex-row flex-wrap justify-evenly max-w-[865px] m-auto'>
          <div className='fade-in-place-children '>
            <img src={inPublicAssets('partners/ldm.svg')} alt="Logo ldm"/>
          </div>
          <div className='fade-in-place-children '>
            <img src={inPublicAssets('partners/performly.svg')} alt="Logo Performly"/>
          </div>
          <div className='fade-in-place-children '>
            <img src={inPublicAssets('partners/ditgo.svg')} alt="Logo Ditgo"/>
          </div>
          <div className='fade-in-place-children '>
            <img src={inPublicAssets('partners/mediamGroup.svg')} alt="Logo Mediam Group"/>
          </div>
          <div className='fade-in-place-children '>
            <img src={inPublicAssets('partners/futureMedia.svg')} alt="Logo Future Media"/>
          </div>
        </div>
    </div>
  );
}

export default Partners;
