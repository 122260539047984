import {downArrowIcon} from '../utilities/constants'

function TextLinkArrow(props) {
  return (
    <span className='flex justify-center items-center bg-btnBg border-main transition-colors duration-300 hover:bg-transparent hover:text-main py-2 px-3 rounded-full text-black mb-4 w-[15em] mx-auto'>
      {props.children}
      <span className="link-arrow ml-2 max-w-[20px]">
        <img
          src={downArrowIcon}
          alt="arrow"
          className="w-auto h-2 object-contain inline-block"
        />
      </span>
    </span>
  )
}


export default TextLinkArrow;