import InputTypeFile from './input-type-file'
import Select from './select'
import SelectMulti from 'react-select'
import { Controller } from 'react-hook-form'


function Input({
  type = 'text',
  name = 'text', //field name,
  id = '', //field id,
  register = () => {}, //register function from react-hook-form,
  validation = {}, //validation object from react-hook-form,
  placeholder = '', //placeholder text,
  options = [], //options array,
  label = '',
  watch = () => {},
  errors = {},
  control = null,
  switchOptions = null,
}) {
  let selectOptions = switchOptions?.field
    ? createOptions(switchOptions.field)
    : options

  function createOptions(fieldName) {
    //console.log(watch(switchOptions.field))

    const dependentFieldValue = watch(fieldName)
    if (!Boolean(dependentFieldValue)) {
      return []
    }

    const options = switchOptions.options?.[dependentFieldValue]

    if (!Boolean(options)) {
      console.log(
        'no matching entry for value: ',
        dependentFieldValue,
        ' on json config for select: ',
        name
      )
      return []
    }
    return options
  }

  if (type === 'select') {
    return (
      <Select
        name={name}
        id={id}
        register={register}
        validation={validation}
        placeholder={placeholder}
        options={selectOptions}
        watch={watch}
        errors={errors}
      />
    )
  }

  if (type === 'file') {
    return (
      <InputTypeFile
        name={name}
        id={id}
        register={register}
        validation={validation}
        placeholder={placeholder}
        watch={watch}
      />
    )
  }
  if (type === 'textarea') {
    return (
      <textarea
        name={name}
        id={id}
        tabIndex={0}
        className="w-full h-20"
        {...register(name, {
          ...validation,
        })}
      />
    )
  }

  if (type === 'multiselect') {
    return (
      <Controller
        control={control}
        name={name}
        id={id}
        defaultValue={[]}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <SelectMulti
            className="selectMulti"
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            options={selectOptions}
            placeholder="Selecciona un área"
            isMulti={true}
          />
        )}
      />
    )
  }

  if (['email', 'text', 'tel'].includes(type)) {
    return (
      <input
        placeholder={placeholder || label || null}
        type={type}
        name={name}
        id={id}
        tabIndex={0}
        {...register(name, {
          ...validation,
        })}
      />
    )
  }
}

export default Input
